(function () {
    'use strict';

    angular.module('aerosApp')
        .directive('aflModalCarouselSection', aflModalCarouselSection);

    aflModalCarouselSection.$inject = [];

    function aflModalCarouselSection() {

        var directive = {
            restrict: 'E',
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-modal-carousel-section/afl-modal-carousel-section.html',
            controller: aflModalCarouselSectionController,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                group: '=',
                generic: '=',
                mode: '=',
                fields: '=',
                show: '=',
                headerClick: '&',
                presets: '=',
                selectedPreset: '=',
                toolModel: '=',
                definition: '=',
                savePreset: '&',
                newPresetsName: '=',
                saveAsPresetOpened: '@'
            },
            link: {
                pre: function preLink(scope) {
                    if (scope.group && !scope.toolModel[scope.group.id]) {
                        scope.toolModel[scope.group.id] = {};
                    }
                }
            }
        };

        return directive;
    }

    aflModalCarouselSectionController.$inject = ['ToolService', 'UserProfileService'];

    function aflModalCarouselSectionController(ToolService, UserProfileService) {

        var $scope = this;

        angular.extend($scope, {
            showCurrentTab: showCurrentTab,
            setFromPreset: setFromPreset,
            fieldChangeEventHandler: fieldChangeEventHandler,
            isVisible: isVisible,
            saveAsPresetIsDisabled: saveAsPresetIsDisabled,
            showSaveAsPresetButton: showSaveAsPresetButton,
            lossDistUnit: UserProfileService.get().user.preferredLossDistanceUnit
        });

        // Match and set the preset name
        presetMatchGroup($scope.toolModel, true);

        function setFromPreset(selected, key) {
            if (selected) {
                var obj = angular.fromJson(selected);

                for (var field in obj.fields) {
                    $scope.toolModel[key+'.'+field] = obj.fields[field];
                }
            }
        }

        function showCurrentTab(group) {
            $scope.show = group;
        }

        function presetSet(fields) {
            // This will match and set the group which is shown
            // Which is set in $scope.group.id
            presetMatchGroup(fields, true) ;
        }

        function fieldChangeEventHandler(fields) {
            presetMatchGroup(fields, false) ;
        }

        function presetMatchGroup(fields, useHint) {

            do {
                var found = false;

                //No preset selected, let's try to find a match between the values in the tool and any of the presets
                if ($scope.presets) {

                    var definitionGroup = $scope.definition.groups.find(function (definitionGroup) {
                        return definitionGroup.id === $scope.group.id;
                    });

                    if (!useHint) {
                        $scope.selectedPreset[$scope.group.id] = '' ;
                    }
                    else if ($scope.selectedPreset[$scope.group.id] &&
                        angular.fromJson($scope.selectedPreset[$scope.group.id]).name) {
                        $scope.toolModel[$scope.group.id + ".name"] = angular.fromJson($scope.selectedPreset[$scope.group.id]).name ;
                        break ;
                    }

                    // Ticket 3610 - dup presets, use preset stored in the tool as a hint
                    if ($scope.presets[$scope.group.id]) {
                        var hintPresetName = $scope.toolModel[$scope.group.id + ".name"];
                        var hintPreset = $scope.presets[$scope.group.id].find(function (pr) {
                            return pr.name === hintPresetName;
                        });
                        if (hintPreset && useHint) {
                            // We found hind preset with the same name
                            // Check if it matches the values in the tool
                            if ((!found) &&
                                ToolService.compareModelFieldWithPreset($scope.toolModel, hintPreset.fields,
                                    definitionGroup.fields, definitionGroup)) {
                                found = true;
                                // The "hint preset" matched one of the existing presets
                                // And it will in shown as selected now
                                var group = $scope.group.id;
                                $scope.selectedPreset[$scope.group.id] = angular.toJson(hintPreset);
                                break;
                            }
                        }
                    }

                    angular.forEach($scope.presets[$scope.group.id], function (preset) {

                        if ((!found) && ToolService.compareModelFieldWithPreset($scope.toolModel, preset.fields,
                            definitionGroup.fields, $scope.group)) {

                            found = true;
                            // This will change the selected preset value only if it is empty
                            var group = $scope.group.id;
                            $scope.selectedPreset[$scope.group.id] = angular.toJson(preset);

                            // And we will overwrite the value of the "hint preset"
                            $scope.toolModel[definitionGroup.id + ".name"] = preset.name ;

                            // Fix the drop down selection of presets
                            // so the match is shown at the top of the drop-down list
                            var firstPreset = $scope.presets[$scope.group.id][0];
                            var index = $scope.presets[$scope.group.id].indexOf(preset);
                            $scope.presets[$scope.group.id][index] = firstPreset;
                            $scope.presets[$scope.group.id][0] = preset;

                        } else if (!found) {
                            //Clear the preset name from the tool, since no match was found
                            var group = $scope.group.id;
                            $scope.toolModel[group + ".name"] = "";
                        }
                    });
                }

                if (!found) {
                    $scope.selectedPreset[$scope.group.id] = '';
                }
            } while (false) ;
        }

        function isVisible(field, toolModel) {
            return field.fieldDescription.parent ?  visible(field, toolModel) : field.fieldDescription.type != 'hidden';
        }

        function visible(field, model) {
            var parentName = field.parent || field.fieldDescription.parent,
                description = field.fieldDescription || field;

            if (description.parent_value && model[parentName] && description.values[model[parentName][description.parent_value]] && description.values[model[parentName][description.parent_value]].parent) {

                return visible(description.values[model[parentName][description.parent_value]], model)
            } else {
                return  (description.values[model[parentName]]) || (description.parent_value && model[parentName] && description.values[model[parentName][description.parent_value]])
            }
        }

        function getForm() {
            if ($scope.group.id) {
                var formName = $scope.group.id + 'Form';
                return angular.element('#' + formName).controller("form");
            }
        }

        function saveAsPresetIsDisabled() {
            var form = getForm();

            return !(form && !form.$invalid);
        }

        function showSaveAsPresetButton() {
            // This method was added to address 3561
            // and disable showing saveAsPreset button while editing ($scope.mode != 3) or
            // creating ($scope.mode != 4) tools in Project scope

            // Match and set the preset name
            presetMatchGroup($scope.toolModel, true);

            var result = ($scope.mode === 1 ||  $scope.mode === 2) &&
                $scope.selectedPreset[$scope.group.id] == '' ;
            return result ;
        }

    }
})();
